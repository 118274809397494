import React, { useEffect } from "react";
import { Link, graphql } from "gatsby";
import Layout from "../../components/layout.pt";
import SEO from "../../components/seo";
import { WOW } from "wowjs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import BackgroundImage from "gatsby-background-image";

const ServicesPagePT = ({ data }) => {
    useEffect(() => {
        const wow = new WOW({
            boxClass: "wow",
            animateClass: "animate",
            offset: 100,
            mobile: false,
            live: true,
        });

        wow.init();
    });

    return (
        <Layout>
            <SEO title="Serviços" description="Conheça quais são nossas indústrias de especialização" lang="pt" />

            <BackgroundImage backgroundColor={`#040e18`} fluid={data.heroImage.childImageSharp.fluid} className="brook-breadcaump-area ptb--270 pt_md--190 pb_md--100 pt_sm--190 pb_sm--100 breadcaump-title-bar breadcaump-title-white" data-black-overlay="8" Tag="div">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="breadcaump-inner text-center">
                                <h6 className="heading heading-h6 text-white">Serviços</h6>
                                <div className="bkseparator--30"></div>
                                <h1 className="heading heading-h1 text-white line-height-1 font-80">O nosso portfólio</h1>
                                <div className="bkseparator--30"></div>
                                <h5 className="heading heading-h5 text-white line-height-1-95">
                                    Com 7 anos de experiência em web development, <br />
                                    temos orgulho de ter trabalhado e ganho conhecimentos nas seguintes indústrias
                                </h5>
                            </div>
                        </div>
                    </div>
                </div>
            </BackgroundImage>

            <main className="page-content">
                <div className="brook-icon-boxes-area basic-thine-line pb--100 pb_md--80 pb_sm--80">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="row bg_color--1 pt--100 space_dec--110 poss_relative basic-thick-line-theme-4">
                                    <div className="col-lg-4 col-md-6 col-sm-6 col-12 wow move-up">
                                        <div className="icon-box text-center no-border">
                                            <div className="inner">
                                                <div className="icon">
                                                    <FontAwesomeIcon icon={["fal", "prescription-bottle"]} />
                                                </div>
                                                <div className="content">
                                                    <h5 className="heading heading-h5">Indústria Farmacêutica</h5>
                                                    <p className="bk_pra">Com mais de 5 anos de experiência no desenvolvimento e suporte nas áreas de marketing e vendas às indústrias farmacêuticas, estamos agora focados em tecnologias de IA.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt_mobile--70 wow move-up">
                                        <div className="icon-box text-center no-border">
                                            <div className="inner">
                                                <div className="icon">
                                                    <FontAwesomeIcon icon={["fal", "tshirt"]} />
                                                </div>
                                                <div className="content">
                                                    <h5 className="heading heading-h5">Moda</h5>
                                                    <p className="bk_pra">
                                                        A nossa experiência em moda estende-se a diferentes áreas. Criamos sites de e-commerce, desenvolvemos estratégias de gestão de redes sociais e fotografamos campanhas. O nosso conhecimento permite-nos aconselhar os nossos clientes e pensar numa
                                                        perspetiva de cliente B2B e B2C.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt_md--70 mt_sm--70 wow move-up">
                                        <div className="icon-box text-center no-border">
                                            <div className="inner">
                                                <div className="icon">
                                                    <FontAwesomeIcon icon={["fal", "vial"]} />
                                                </div>
                                                <div className="content">
                                                    <h5 className="heading heading-h5">Cosmética</h5>
                                                    <p className="bk_pra">
                                                        Trabalhamos com marcas de cosméticos desde a nossa existência, através do desenvolvimento de soluções web (e-commerce, CRM, gestão de conteúdo, plataformas B2B) e captura de fotografias de produto, para redes sociais ou e-commerce.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt--70 wow move-up">
                                        <div className="icon-box text-center no-border">
                                            <div className="inner">
                                                <div className="icon">
                                                    <FontAwesomeIcon icon={["fal", "shopping-cart"]} />
                                                </div>
                                                <div className="content">
                                                    <h5 className="heading heading-h5">Retalho e fidelização</h5>
                                                    <p className="bk_pra">
                                                        Sites de e-commerce e programas de fidelização fazem parte do nosso expertise. Cada projeto é personalizado de acordo com as necessidades do cliente e as características do negócio. Desenvolvemos um software interno, o Whitebrim, que nos
                                                        permite criar todas as soluções com a máxima eficiência.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt--70 wow move-up">
                                        <div className="icon-box text-center no-border">
                                            <div className="inner">
                                                <div className="icon">
                                                    <FontAwesomeIcon icon={["fal", "chart-line"]} />
                                                </div>
                                                <div className="content">
                                                    <h5 className="heading heading-h5">CRM / ERP</h5>
                                                    <p className="bk_pra">Somos especializados na criação de CRM e ERP personalizados, que permitem aos nossos clientes gerir com eficácia os dados dos clientes e melhorar e integrar processos internos, a fim de inovar e escalar os seus negócios.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt--70 wow move-up">
                                        <div className="icon-box text-center no-border">
                                            <div className="inner">
                                                <div className="icon">
                                                    <FontAwesomeIcon icon={["fal", "ad"]} />
                                                </div>
                                                <div className="content">
                                                    <h5 className="heading heading-h5">Publicidade</h5>
                                                    <p className="bk_pra">Desenvolvemos display ads, banners, email marketing, landing pages, e-detailing e ativações de marca, em parceria com outras agências, de forma a aumentar a notoriedade da marca.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="brook-gradation-area ptb--100 ptb-md--80 ptb-sm--60 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="brook-section-title text-center">
                                    <div className="bkseparator--35"></div>
                                    <h3 className="heading heading-h3">Work process</h3>
                                </div>
                                <div className="bk-gradation mt--60 mt_sm--5">
                                    <div className="item-grid custom-color--2 mt--40 move-up-x wow" data-wow-delay=".13s">
                                        <div className="line"></div>
                                        <div className="dot-wrap">
                                            <div className="dot">
                                                <div className="count">1</div>
                                            </div>
                                        </div>
                                        <div className="content">
                                            <h5 className="heading heading-h5">Brainstorming</h5>
                                            <p className="bk_pra">Estudo cuidadoso do desafio proposto e elaboração de conceitos criativos.</p>
                                        </div>
                                    </div>
                                    <div className="item-grid custom-color--3 mt--40 move-up-x wow" data-wow-delay=".19s">
                                        <div className="line"></div>
                                        <div className="dot-wrap">
                                            <div className="dot">
                                                <div className="count">2</div>
                                            </div>
                                        </div>
                                        <div className="content">
                                            <h5 className="heading heading-h5">Desenvolvimento</h5>
                                            <p className="bk_pra">Com os paços bem definidos, tornamos a visão realidade.</p>
                                        </div>
                                    </div>
                                    <div className="item-grid custom-color--4 mt--40 move-up-x wow" data-wow-delay=".2s">
                                        <div className="line"></div>
                                        <div className="dot-wrap">
                                            <div className="dot">
                                                <div className="count">3</div>
                                            </div>
                                        </div>
                                        <div className="content">
                                            <h5 className="heading heading-h5">Lançamento e acompanhamento</h5>
                                            <p className="bk_pra">Lançamentos planeados e acompanhamento a longo prazo</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="brook-call-to-action bg_color--2 ptb--70">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-6 col-sm-6 col-12">
                                <div className="call-content text-center text-sm-left">
                                    <h3 className="heading heading-h3 text-white wow move-up">Trabalhe connosco</h3>
                                </div>
                            </div>
                            <div className="col-lg-6 col-sm-6 col-12">
                                <div className="call-btn text-center text-sm-right mt_mobile--20 wow move-up">
                                    <Link className="brook-btn bk-btn-white text-theme btn-sd-size btn-rounded" to="/pt/contacte-nos/">
                                        Contacte-nos
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </Layout>
    );
};

export default ServicesPagePT;

export const query1 = graphql`
    query {
        heroImage: file(relativePath: { eq: "unsplash_5.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 1920, quality: 100) {
                    base64
                    aspectRatio
                    src
                    srcSet
                    sizes
                    originalImg
                }
            }
        }
    }
`;
